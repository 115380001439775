.bp3-multi-select-popover .bp3-menu {
    max-width: 600px !important;
}

::-webkit-scrollbar {
    width: 10px;
    left: -100px;
}

::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //margin-left: 10px;
    background: #D5E3F0;
    border-radius: 5px;
    //border-left: 10px solid transparent;
    //box-sizing: border-box !important;
    //box-shadow: inset 0 0 10px 10px green;
    //transform: rotate(-90deg);
}

::-webkit-scrollbar-thumb {
    background-color: #034781;
    border-radius: 5px;
    width: 10px;
    //outline: 1px solid slategrey;
    //border-left: 5px solid transparent;
    //outline: none;
    //margin-left: 10px;
}
